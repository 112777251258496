<template>
  <div class="TestKitDetail">

    <div class="TestKitDetail-form">
      <el-form ref="form" label-width="160px" :hide-required-asterisk="true" :rules="rules" :model="form" @submit.native.prevent size="small" >

        <div class="TestKitDetail-form-content">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" clearable placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCardNo">
            <el-input v-model="form.idCardNo" @change="idCardNoChange" clearable placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="form.gender">
              <el-radio label="0">女</el-radio>
              <el-radio label="1">男</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input v-model="form.age" clearable placeholder="请输入年龄"></el-input>
          </el-form-item>
          <el-form-item label="身高" prop="height">
            <el-input v-model="form.height" clearable placeholder="请输入身高">
              <template #append>
                <span>厘米</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="体重" prop="weight">
            <el-input v-model="form.weight" clearable placeholder="请输入体重">
              <template #append>
                <span>公斤</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="收缩压（选填）" prop="systolicPressure" v-if="form.sort == 'taxi'" >
            <el-input v-model="form.systolicPressure" clearable placeholder="请输入收缩压">
              <template #append>
                <span>mmHg</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="舒张压（选填）" prop="diastolicPressure" v-if="form.sort == 'taxi'" >
            <el-input v-model="form.diastolicPressure" clearable placeholder="请输入舒张压">
              <template #append>
                <span>mmHg</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="民族" prop="nation" v-if="form.sort == 'taxi'" >
            <el-radio-group v-model="form.nation">
              <el-radio label="0">汉</el-radio>
              <el-radio label="1">回</el-radio>
              <el-radio label="2">满</el-radio>
              <el-radio label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday" v-if="form.sort == 'taxi'" >
            <el-date-picker
                v-model="form.birthday"
                clearable
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请输入出生日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="教育程度" prop="educationLevel">
            <el-radio-group v-model="form.educationLevel">
              <el-radio v-for="item in $store.state.educationLevelList"
                        :key="item.dictKey"
                        :label="item.dictKey">{{ item.dictValue }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="婚姻状况" prop="maritalStatus">
            <el-radio-group v-model="form.maritalStatus">
              <el-radio v-for="item in $store.state.maritalStatusList"
                        :key="item.dictKey"
                        :label="item.dictKey">{{ item.dictValue }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="职业" prop="occupation" v-if="form.sort == 'normal'" >
            <el-radio-group v-model="form.occupation">
              <el-radio v-for="item in $store.state.occupation"
                        :key="item.dictKey"
                        :label="item.dictKey">{{ item.dictValue }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="医保类型" prop="medicalInsuranceType">
            <el-checkbox-group v-model="form.medicalInsuranceType">
              <el-checkbox v-for="item in $store.state.medicalInsuranceTypeList"
                           :key="item.dictKey"
                           :label="item.dictKey">{{ item.dictValue }}</el-checkbox>
            </el-checkbox-group>
<!--            <el-radio-group v-model="form.medicalInsuranceType">-->
<!--              <el-radio label="0">城镇职工</el-radio>-->
<!--              <el-radio label="1">城镇居民</el-radio>-->
<!--              <el-radio label="2">公费医疗</el-radio>-->
<!--              <el-radio label="3">商业保险</el-radio>-->
<!--              <el-radio label="4">自费</el-radio>-->
<!--              <el-radio label="5">其他</el-radio>-->
<!--            </el-radio-group>-->
          </el-form-item>
          <el-form-item label="地址" prop="residentialAddress" v-if="form.sort == 'taxi'" >
            <el-select v-model="form.residentialAddress" clearable placeholder="请选择地址">
              <el-option
                  v-for="item in nresidentialAddressList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="住址" prop="detailAddress" v-if="form.sort == 'normal'" >
            <el-input v-model="form.detailAddress" clearable placeholder="请输入住址"></el-input>
          </el-form-item>
          <el-form-item label="受检人手机号" prop="phoneNoOne">
            <el-input v-model="form.phoneNoOne" clearable placeholder="请输入受检人手机号"></el-input>
          </el-form-item>
          <el-form-item label="其他联系方式（选填）" prop="phoneNoTwo">
            <el-input v-model="form.phoneNoTwo" clearable placeholder="请输入其他联系方式"></el-input>
          </el-form-item>
          <el-form-item label="试剂盒编号" prop="reagentNo">
            <el-input v-model="form.reagentNo" clearable placeholder="请输入试剂盒编号"></el-input>
          </el-form-item>
          <el-form-item label="填表日期" prop="fillFormDate">
            <el-date-picker
                v-model="form.fillFormDate"
                clearable
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请输入填表日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发放人" prop="inviter" v-if="form.sort == 'normal'" >
            <el-input v-model="form.inviter" clearable placeholder="请输入发放人"></el-input>
          </el-form-item>
          <el-form-item label="领取日期（选填）" prop="receiveDate">
            <el-date-picker
                v-model="form.receiveDate"
                clearable
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请输入领取日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="回收日期（选填）" prop="recoverDate">
            <el-date-picker
                v-model="form.recoverDate"
                clearable
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请输入回收日期">
            </el-date-picker>
          </el-form-item>


          <template>
            <div class="TestKitDetail-form-content-list" v-for="(item,index) in TestKitList" :key="index" >
              <div class="TestKitDetail-form-content-list-title">
                {{ item.category }}
              </div>
              <template>
                <div v-for="(items,indexs) in item.questionList" :key="indexs" >
                  <el-form-item
                      v-if="items.isShow == '1' && items.type == '0'"
                      :label="items.title"
                      :prop="'value'+items.questionId">
                    <el-radio-group v-model="items['value'+items.questionId]" @change="questionChange($event,items)" >
                      <el-radio v-for="itemss in items.answerList" :key="itemss.answerId" :label="itemss.answerId">{{ itemss.answerContent }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </template>
            </div>
          </template>

          <el-form-item label="问卷风险结果（选填）" prop="questionnaireResult" v-if="reagentId" >
            <el-select v-model="form.questionnaireResult" clearable placeholder="请选择问卷风险结果">
              <el-option
                  v-for="item in questionnaireResult_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="检测结果（选填）" prop="detectResult" v-if="reagentId" >
            <el-select v-model="form.detectResult" clearable placeholder="请选择检测结果">
              <el-option
                  v-for="item in detectResult_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客服呼叫备注（选填）" prop="serviceCallRecord" v-if="reagentId" >
            <el-input type="textarea" v-model="form.serviceCallRecord" clearable placeholder="请输入客服呼叫备注"></el-input>
          </el-form-item>
        </div>



        <el-form-item>
          <div class="TestKitDetail-form-button">
            <el-button @click="onsubmit(form)" type="primary" native-type="submit" >保存</el-button>
            <el-button @click="cancel">取消</el-button>
          </div>
        </el-form-item>

      </el-form>
    </div>

  </div>
</template>

<script>
import idCardNo from "../../utils/idCardNo";
var validatorIdCard = (rule, value, callback) => {
  if(idCardNo.idCardNo.checkIdCardNo(value)) {
    callback();
  }else {
    callback(new Error('身份证号不正确'));
  }
}

export default {
  name: "TestKitDetail",
  data() {
    return {
      form: {
        name: null, // 姓名
        idCardNo: null, // 身份证号
        age: null, // 年龄
        gender: null, // 性别
        height: null, // 身高
        weight: null, // 体重
        diastolicPressure: null, // 舒张压
        systolicPressure: null, // 收缩压
        nation: null, // 民族
        birthday: null, // 出生日期
        educationLevel: null, // 教育成都
        maritalStatus: null, // 婚姻状态
        medicalInsuranceType: [], // 医保类型
        residentialAddress: null, // 地址
        phoneNoOne: null, // 受检人手机号
        phoneNoTwo: null, // 其他联系方式
        reagentNo: null, // 试剂盒编号
        questionnaireResult: null, // 问卷风险结果
        detectResult: null, // 检测结果
        fillFormDate: null, // 填表日期
        receiveDate: null, // 领取日期
        recoverDate: null, // 回收日期
        serviceCallRecord: null, // 客服呼叫备注
        sort: this.$route.query.state,
        occupation: null, // 职业
        detailAddress: null, // 住址
        inviter: null, // 发放人
      },
      reagentId: this.$route.query.reagentId ? this.$route.query.reagentId : null,
      rules: {
        name: [
            { required: true, message: '请输入姓名', trigger: 'change' },
        ],
        idCardNo: [
            { required: true, message: '请输入身份证号', trigger: 'change' },
            { validator: validatorIdCard, trigger: 'change' },
        ],
        age: [
            { required: true, message: '请输入年龄', trigger: 'change' },
        ],
        gender: [
            { required: true, message: '请选择性别', trigger: 'change' },
        ],
        height: [
            { required: true, message: '请输入身高', trigger: 'change' },
        ],
        weight: [
            { required: true, message: '请输入体重', trigger: 'change' },
        ],
        // diastolicPressure: [
        //     { required: true, message: '请输入舒张压', trigger: 'change' },
        // ],
        // systolicPressure: [
        //     { required: true, message: '请输入收缩压', trigger: 'change' },
        // ],
        nation: [
            { required: true, message: '请选择民族', trigger: 'change' },
        ],
        fillFormDate: [
          { required: true, message: '请选择填表日期', trigger: 'change' },
        ],
        birthday: [
            { required: true, message: '请选择出生日期', trigger: 'change' },
        ],
        educationLevel: [
            { required: true, message: '请选择教育程度', trigger: 'change' },
        ],
        maritalStatus: [
            { required: true, message: '请选择婚姻状态', trigger: 'change' },
        ],
        medicalInsuranceType: [
            { required: true, message: '请选择医保类型', trigger: 'change' },
        ],
        residentialAddress: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        phoneNoOne: [
            { required: true, message: '请输入受检人手机号', trigger: 'change' },
            { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '受检人手机号不正确', trigger: 'change' },
        ],
        phoneNoTwo: [
            // { required: true, message: '请输入其他联系方式', trigger: 'change' },
            { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '其他联系方式不正确', trigger: 'change' },
        ],
        reagentNo: [
            { required: true, message: '请输入试剂盒编号', trigger: 'change' },
            { min: 6, max: 10, message: '长度为 6 到 10 个字符', trigger: 'change' }
        ],
        occupation: [
          { required: true, message: '请选择职位', trigger: 'change' },
        ],
        detailAddress: [
          { required: true, message: '请驶入住址', trigger: 'change' },
        ],
      },
      questionnaireResult_list: [
        {
          label: "低级",
          value: "低级"
        },
        {
          label: "中级",
          value: "中级"
        },
        {
          label: "高级",
          value: "高级"
        },
      ],
      detectResult_list: [
        {
          label: "阴性",
          value: "阴性"
        },
        {
          label: "阳性",
          value: "阳性"
        },
      ],
      TestKitList: [],
      nresidentialAddressList: []
    }
  },
  created() {


    this.address();
    this.$store.dispatch("setOccupation",this.$route.query.state);
    this.$store.dispatch("setEducationLevelList",this.$route.query.state);
    this.$store.dispatch("setMaritalStatusList",this.$route.query.state);
    this.$store.dispatch("setMedicalInsuranceTypeList",this.$route.query.state);

    let obj= {};
    obj["activityType"]= this.$route.query.state;
    if(this.$route.query.reagentId) {
      obj["reagentId"]= this.$route.query.reagentId;
      this.get_info({reagentId: this.$route.query.reagentId});
    }

    this.get_question(obj);
  },
  methods: {
    address() {
      this.$get("base/reagent/address")
        .then(res => {
          console.log(res)
          this.nresidentialAddressList= res.data;
        })
    },
    questionChange(e,y) {

      if(e == y.logicAnswerId) {
        y.showId.forEach((item) => {
          this.TestKitList.forEach((items) => {
            items.questionList.forEach((itemss) => {
              if(itemss.questionId == item) {
                itemss.isShow= "1";
              }
            })
          })
        })
      }else {
        y.showId.forEach((item) => {
          this.TestKitList.forEach((items) => {
            items.questionList.forEach((itemss) => {
              if(itemss.questionId == item) {
                itemss.isShow= "0";
                if(itemss.type == '2') {
                  itemss.childrenQuestionVOList.forEach((itemsss) => {
                    itemsss.value= null;
                  })
                }else if(itemss.type == '0') {
                  itemss['value'+itemss.questionId]= null;
                }

              }
            })
          })
        })
      }

    },
    get_info(y) {
      this.$get("tempReagent/info",y)
        .then(res => {
          console.log(res);

          this.form= res.data;

        })
    },
    get_question(y) {

      this.$get("tempReagent/question",y)
        .then(res => {
          console.log(res.data);

          res.data.forEach(item => {
            item.questionList.forEach(items => {
              if(items.value) {
                items["value" + items.questionId]= items.value;
              }else {
                items["value" + items.questionId]= null
              }

            })
          })

          this.TestKitList= res.data;

        })

    },
    onsubmit(y) {

      this.$refs["form"].validate((valid => {
        if(valid) {

          let arr= [];
          this.TestKitList.forEach(item => {
            item.questionList.forEach(items => {
              if(items.isShow === '1') {
                switch (items.type) {
                  case 0: {
                    let obj= {};
                    obj["questionId"]= items.questionId;
                    obj["answerId"]= items["value" + items.questionId];
                    obj["answerStr"]= null;
                    arr.push(obj);
                    break;
                  }
                  case 2: {
                    items.childrenQuestionVOList.forEach((itemss) => {
                      let obj= {};
                      obj["questionId"]= itemss.questionId;
                      obj["answerId"]= null;
                      obj["answerStr"]= itemss.value;
                      arr.push(obj);
                    })
                    break;
                  }
                }
              }
            })
          })

          let obj= {};
          obj["info"]= y;
          obj["questionAndAnswer"]= arr;
          obj["reagentId"]= this.reagentId;

          this.$post("tempReagent/add",obj)
              .then(res => {
                console.log(res);

                if(res) {
                  this.$message.success(res.message);
                  this.$router.go(-1);
                }else {
                  this.$message.error(res.message);
                }

              })

        }
      }))


    },
    cancel() {
      this.$router.go(-1);
    },
    idCardNoChange(e) {

      this.$refs["form"].validateField("idCardNo",valid => {
        if(!valid) {

          this.form.birthday= idCardNo.idCardNo.getIdCardNoInfo(e).dob;
          this.form.age= this.GetAge(e);
          if((e[e.length-2] % 2) == 0) {
            this.form.gender= "0";
          }else {
            this.form.gender= "1";
          }

        }else {
          this.form.birthday= null;
          this.form.age= null;
          this.form.gender= null;
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../style/TestKit/TestKitDetail";
</style>
